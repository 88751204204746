<template>
  <div class="font-montserrat top">
    <div class="row py-24 px-12 oonpay-bg">
      <div class="col-md-12">
        <h4 class="font-semibold text-center text-oonpaytext text-3xl">
          Edit Your Account
        </h4>
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 py-5">
          <div class="form-group text-center mb-3">
            <div class="container">
              <div class="py-8">
                <!-- If you wish to reference an existing file (i.e. from your database), pass the url into imageData() -->
                <div class="file-input flex items-center">
                  <!-- Preview Image -->
                  <div
                    class="h-20 w-20 rounded-full overflow-hidden bg-gray-100"
                  >
                    <!-- Placeholder image -->
                    <div v-if="!url">
                      <svg
                        class="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                    </div>
                    <!-- Show a preview of the photo -->
                    <div
                      v-if="url"
                      class="h-20 w-20 rounded-full overflow-hidden"
                    >
                      <img
                        :src="
                          `${baseUrl}/oonpayusers/${account_info.imageUrl}`
                        "
                        alt=""
                        class="h-20 w-20 object-cover"
                      />
                    </div>
                  </div>

                  <div class="flex items-center">
                    <!-- File Input -->
                    <div class="ml-5 rounded-md shadow-sm">
                      <!-- Replace the file input styles with our own via the label -->
                      <input
                        @change="handleImage"
                        x-ref="input"
                        type="file"
                        accept="image/*,capture=camera"
                        name="photo"
                        id="photo"
                        class="custom"
                      />
                      <label
                        for="photo"
                        class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-indigo-500 hover:border-indigo-300 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-800 transition duration-150 ease-in-out"
                      >
                        Upload Photo
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="firstname">First Name</label>
            <input
              type="text"
              v-model="account_info.firstName"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <label for="lastname">Last Name</label>
            <input
              type="text"
              v-model="account_info.lastName"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <label for="email">Email Address</label>
            <input
              type="email"
              v-model="account_info.email"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <label for="phone_number">Phone Number</label>
            <input
              type="tel"
              disabled
              v-model="account_info.phoneNumber"
              class="form-control bg-grey-400"
            />
          </div>

          <button
            @click.prevent="updateProfile"
            @keydown.enter="updateProfile"
            type="button"
            class="btn btn-primary btn-block"
          >
            <b-spinner v-if="loading" type="grow" ></b-spinner>
            <span v-else>Update Account</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- TO DO: change profile image url -->

<script>
/* eslint-disable no-mixed-spaces-and-tabs */
import { mapGetters } from "vuex";
import { baseUrl } from '../../config';


export default {
  data() {
    return {
      baseUrl,
      loading: false,
      url: JSON.parse(localStorage.getItem("account_info")).imageUrl,
      account_info: JSON.parse(localStorage.getItem("account_info")),
      logo: null
    };
  },

  components: {},

  computed: {
    ...mapGetters("foodhub", ["supercategories"])
  },

  methods: {
    handleImage(e) {
      this.logo = e.target.files[0];
      this.url = URL.createObjectURL(this.logo);
    },

    updateProfile() {
      this.loading = true;
      const formData = new FormData();
      formData.append("firstname", this.account_info.firstName);
      formData.append("lastname", this.account_info.lastName);
      formData.append("email", this.account_info.email);
      formData.append("countryId", this.account_info.countryId);
      formData.append("phoneNumber", this.account_info.phoneNumber);
      formData.append("GUID", this.account_info.guid);
      formData.append("TimeStamp", this.account_info.timeStamp);
      formData.append("logo", this.logo);

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      this.$store
        .dispatch("default/updateClientProfile", formData)
        .then((res) => {
          if (res.status == 200) {
            this.$store.dispatch("default/getAccountInfo", res.data.item1);
            this.loading = false;
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Account Updated successfully"
            });
            location.href = "/";
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    }
  }
};
</script>

<style scoped>
input[type="file"].custom {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

input:disabled {
  background-color: #e6e3e1;
}

.oonpay-bg {
  background: #0c4369;
}

@media screen and (max-width: 720px) {
  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
